<template>
<div>
  <v-row>
    <v-col cols="12">
      <strong>Indique quais itens esse Fornecedor atende. </strong> 
      <v-divider/>
    </v-col>
    <v-col cols="12">
      <v-form ref="form" v-model="formValid" lazy-validation> 
        <v-row>
          <v-col cols="12" sm="4">
            <lookup-dominio label="* Tipo Item"
                            :hidden-clear="true"
                            hint="Selecione o Tipo"
                            v-model="item.idTipoItem"
                            :type="lookups.tipoItem"
                            />
          </v-col>
          <v-col cols="12" md="6">
            <search-box ref="comboOfertas"
                label="* Oferta"
                :emit-all-properties="true"
                popup-label="Selecione um Oferta"
                :id.sync="item.idOferta"
                :descricao.sync="item.descricaoOferta"
                :descTipoItem.sync="item.descricaoTipoItem"
                :hidden-clear="true"
                :value="oferta"
                :loader-fn="loadeOferta"
                item-key="id"
                item-text="descricao"
                :rules="[
                    this.$validators.notNullOrUndefined
                ]">
              <template v-slot:empty>
                <p class="ma-2">
                  Nenhum Oferta encontrado
                </p>
              </template>
            </search-box>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn :disabled="!formValid" small  color="primary" @click="addOferta()">
              <v-icon>mdi-playlist-plus</v-icon> Adicionar
            </v-btn>                         
          </v-col>            
        </v-row>
      </v-form>
    </v-col>
    <v-col cols="12" class="">
      <v-card class="px-1">
        <v-card-text class="subtitle-1 font-weight-light">
            <v-row>
              <v-col cols="12">
                  <v-icon small left >mdi-bullhorn-outline</v-icon>
                  <span class="font-weight-bold">Itens Ofertados</span>
              </v-col>
              <v-col cols="12" style="height:210px !important;">
                  <div class="overflow-x-auto" style="max-height: 200px">
                      <v-chip color="#EFC00F" class="ma-1" text-color="white bold"
                      v-for="(oferta, index) in ofertas" :key="index">
                          <v-icon left>mdi-bullhorn-outline</v-icon>
                          <a @click="removeOferta(index)">{{oferta.descricaoTipoItemOferta+'/'+oferta.descricaoOferta}}</a>
                      </v-chip>
                  </div>
              </v-col>
            </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</div>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
import {findAll as findAllOferta} from "../../../api/oferta";

export default {
  name: "OfertaFornecedorMultiploForm",
  components: {SearchBox, LookupDominio},  
  props: {
    ofertas: {
      type: Array
    }
  },  
  data() {
    return {
      formValid: false,
      item: {
        idTipoItem: "",
        descricaoTipoItem: "",
        idOferta: "",
        descricaoOferta: "",
      },
      lookups: {
        tipoItem: ItemDeDominio.TIPO_ITEM,
      },
    }
  },  
  watch: {
    'item.idTipoItem': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$refs.comboOfertas.resetValues();
          this.$refs.comboOfertas.search();
        }
      }
    },
  },  
  methods: {
    addOferta() {
      if (this.item.idTipoItem !== "" && parseInt(this.item.idOferta) > 0){
        this.ofertas.push({
          id: "",
          idOferta: this.item.idOferta,
          idTipoItemOferta: this.item.idTipoItem,
          descricaoTipoItemOferta: this.item.descricaoTipoItem,
          descricaoOferta: this.item.descricaoOferta,
          idFornecedor: "",
          nomeCompletoFornecedor: "",
          dataCadastro: "",          
        });
        this.item = {
          idTipoItem: this.item.idTipoItem,
          descricaoTipoItem: "",
          idOferta: "",
          descricaoOferta: "",
        };
        this.$refs.comboOfertas.search();
      } else {
        this.$toast.error(`Informe o Tipo e o Item para incluir`, {
          timeout: 6000
        })
      }
    },
    removeOferta(index) {
      this.ofertas.splice(index, 1);
    },
    loadeOferta(page, search) {
      return findAllOferta(page, {
        descricao: search,
        idTipoItem: this.item.idTipoItem === '' ? '--' : this.item.idTipoItem,
        idsNotIn: this.itensSelecionados
      })
    },
  },
  computed: {
    validators() {
      return this.$validators;
    },
    oferta(){
      if (this.item.idOferta == null) return null;
      return {
        id: this.item.idOferta,
        descricao: this.item.descricaoOferta,
        descTipoItem: this.item.descricaoTipoItem
      }      
    }, 
    itensSelecionados() {
      return (this.ofertas || []).map(d => d.idOferta);
    },       
  }
}
</script>

<style scoped>

</style>